import Api from "@/services/Api";
import { defineStore } from "pinia";

export const useCommentStore = defineStore('CommentStore', {
  // state
  state: () => {
    return {
      comments: [],
      chat: [],
      urls: [],
      error: ""
    }
  },
  getters: {
    getComments(state){
      return state.comments
    },
    getChat(state){
      return state.chat
    },
    getUrls(state){
      return state.urls
    },
    getError(state){
      return state.error
    }
  },
  actions: {
    async getCommentsById(id_ticket){
      try {
        const { data } = await Api.get('comments', { id_ticket })
        this.comments = data.filter((comment) => comment.type === 'comment')
        this.chat = data.filter((comment) => comment.type === 'chat')
      } catch (error) {
        console.log(error)
      }
    },
    async createComment(id_ticket, description: string, cnpj: string | null, exibirChat?:boolean, id_comment?: number | null, urls?: string[], type?: string){
      try {
        const { data, error, message } = await Api.post('comment', {id_ticket, description, id_comment, urls, exibirChat, cnpj, type})
      
        if(error){
          this.error = message
        }

        this.comments = data
        return data
      } catch (error) {
        console.log(error)
      }
    },
    sendUrls(urlList){
      this.urls = urlList
    }
  }
})