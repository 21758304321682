import { defineStore } from "pinia";
export const useNotificationStore  = defineStore("notification", {
  state: () => ({
    notifications: {}, // Armazena o contador de notificações por ticket
    isOpen: false,
  }),
  actions: {
    incrementNotification(ticketId) {
      if (!this.notifications[ticketId]) {
        this.notifications[ticketId] = 0;
      }
      this.notifications[ticketId]++;
    },
    resetNotification(ticketId) {
      if (this.notifications[ticketId]) {
        this.notifications[ticketId] = 0;
      }
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
  },
  getters: {
    getNotificationsByTicket: (state) => (ticketId) => state.notifications[ticketId] || 0,
  },
});






