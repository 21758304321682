
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { useCommentStore } from "@/store/CommentStore";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import moment from "moment";
import { TOptions, uploadMediaFromClient } from "@/services/AzureBlobService";
import { useSocketStore } from "@/store/SocketPinia";
import { useRoute } from "vue-router";
import { useNotificationStore } from "@/store/VisualNotification";

export default defineComponent({
  name: "ModalChat",
  props: {
    arrayComments: Array,
    onlyRead: Boolean
  },
  setup(props) {

    const arrayComment: Ref<any> = ref([]);
    const arrayUrls: Ref<any[]> = ref([]);
    const commentStore = useCommentStore();
    const isEdit: Ref<boolean> = ref(false);
    const textoComentario: Ref<string> = ref("");
    const user: Ref<any> = ref();
    const uploadedFiles: Ref<any> = ref([]);
    const route = useRoute();
    const chat: Ref<any> = ref([]);
    const mensagem: Ref<string> = ref("");
    const urlImagesToPreview: Ref<any> = ref([]);
    const urlImageFromAzure: Ref<any> = ref([]);
    const socketStore = useSocketStore();
    const containerChat = ref<HTMLElement | null>(null)
    const notificationStore = useNotificationStore();
    function editar(texto: string) {
      textoComentario.value = texto
      isEdit.value = !isEdit.value
    }

    function openModal(urls) {
      commentStore.sendUrls(urls)
      const modal = new Modal(document.querySelector('#ModalPreview'))
      modal.show()
    }

    function formatDate(dateString) {
      // Criar um objeto de data a partir da string
      const date = new Date(dateString);
      // Obter as partes da data
      const day = date.getDate(); // Dia do mês
      const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      const month = monthNames[date.getMonth()]; // Nome do mês abreviado
      const hours = date.getHours().toString().padStart(2, '0'); // Hora em formato 24h
      const minutes = date.getMinutes().toString().padStart(2, '0'); // Minutos com zero à esquerda
      // Montar o formato final
      return `${day} ${month} ${hours}:${minutes}`;
    }

    async function deleteComment(id, id_ticket) {
      const res = await Api.delete(`comment/${id}`)
      await commentStore.getCommentsById(id_ticket)
      arrayComment.value = commentStore.getComments

    }

    async function getAuthenticatedUser() {
      const { data } = await Api.get('getAuthenticatedUser')

      if (!data.user) return
      user.value = data.user
    }

    function getUrls(listUrls) {
      arrayUrls.value = []
      listUrls.forEach((item) => arrayUrls.value.push(item.url))
    }

    async function filesChange(event) {
      //salva files e urls em arrays
      const files = Array.from(event.target.files)

      try {
        //populate list of files 
        files.forEach(file => {
          const url = URL.createObjectURL(file as any)

          uploadedFiles.value.push(file)
          urlImagesToPreview.value.push(url)
        });
      } catch (error) {
        console.log("error", error)
      }

    }

    function removeUrl(urls, key) {
      const { url, name } = urls[key]
      urls.splice(key, 1)
      uploadedFiles.value = uploadedFiles.value.filter((e) => e.name != name)

    }

    async function sendImage() {
      //salva imagens na azure e retorna as urls
      let urls = [] as any;
      for (const file of uploadedFiles.value) {
        try {
          const options: TOptions = {
            blobHTTPHeaders: {
              blobContentType: file.type
            }
          }
          const fileName = `${file.name}${new Date().getTime()}`
          const { _response } = await uploadMediaFromClient(file, fileName, options)
          const url = _response.request.url
          if(url){
            urls.push(url);
          }

        } catch (error) {
          console.log(error)
        }
      };
      return urls;
    }

    function clearImageLists() {
      urlImageFromAzure.value = []
      uploadedFiles.value = []
    }

    async function getComments() {
      try {
        const data: any = commentStore.getChat
        chat.value = data.filter((item) => item.type !== 'comment')
        urlImagesToPreview.value = chat.value.map((item) => item.urls.map((url) => url.url)).flat()
      } catch (erro) {
        console.log("erro", erro)
      } finally {
        //
      }
    }

    function ativaSocket() {
      socketStore.startSocket()
      receiveComment()
    }

    function receiveComment() {
      const idTicketAtual = Number(route.params.id);
      const eventName = `${process.env.VUE_APP_ENV}-newComment-${localStorage.getItem('id_project') || '1'}-${idTicketAtual}`
      socketStore.newListen({
        name: eventName,
        callbackFunction: (data) => {
          chat.value = [{ description: data.description, id_cargo: data.id_cargo, username: data.username, urls: data.urls , created_at: data.created_at}, ...chat.value]
          if(!notificationStore.isOpen){
            notificationStore.incrementNotification(idTicketAtual)
          }
        },
      });
    }


    async function sendMessage() {

      // não enviar nada se não tiver mensagem e nem imagem
      if(mensagem.value === "" && uploadedFiles.value.length === 0) return
      
      if(uploadedFiles.value.length > 0){
        urlImageFromAzure.value = await sendImage()
      }

      const messageToSend = mensagem.value
      mensagem.value = ""
      uploadedFiles.value = []
      const { data, error, message } = await Api.post('comment', { id_ticket: route.params.id, description: messageToSend, urls: urlImageFromAzure.value })
      
      if(data){
        clearImageLists()
        chat.value = [{ description: data.comment.description, id_cargo: data.comment.id_cargo, username: data.comment.username, urls: data.comment.urls , created_at: data.comment.created_at}, ...chat.value]
      }
    }

    function openInput() {
      const input = document.querySelector('#input-card-chat') as HTMLElement;
      input.click()
    }

    watch(
      () => props.arrayComments,
      () => {
        arrayComment.value = props.arrayComments
      }
    )

    watch(
      () => commentStore.getComments,
      () => {
        getComments()
      }
    )


    onMounted(async () => {
      await getAuthenticatedUser()
      ativaSocket()
      arrayComment.value = props.arrayComments
    })

    return {
      history,
      mensagem,
      arrayComment,
      openModal,
      arrayUrls,
      getUrls,
      deleteComment,
      editar,
      isEdit,
      textoComentario,
      user,
      filesChange,
      removeUrl,
      uploadedFiles,
      moment,
      chat,
      sendMessage,
      openInput,
      urlImagesToPreview,
      formatDate,
      containerChat,
    };
  },
});
