
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import Title from "@/components/Title.vue"
import { useCommentStore } from "@/store/CommentStore";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import moment from "moment";
import { TOptions, uploadMediaFromClient } from "@/services/AzureBlobService";
import { useLoaderStore } from "@/store/LoaderStore";

export default defineComponent({
  name: "Comments",
  props: {
    arrayComments: Array,
    onlyRead: Boolean
  },
  components: {
    Title,
  },

  setup(props) {

    const arrayComment: Ref<any> = ref([]);
    const arrayUrls: Ref<any[]> = ref([]);
    const commentStore = useCommentStore();
    const isEdit: Ref<boolean> = ref(false);
    const textoComentario: Ref<string> = ref("");
    const user: Ref<any> = ref();
    const uploadedFiles: Ref<any> = ref([]);
    const centerDialogVisible = ref(false);
    const loader = useLoaderStore();

    function editar(texto: string) {
      textoComentario.value = texto
      isEdit.value = !isEdit.value
    }

    function openModal(urls) {
      commentStore.sendUrls(urls)
      const modal = new Modal(document.querySelector('#ModalPreview'))
      modal.show()
    }

    const commentClicked:Ref<any | null> = ref(null)

    function openInput(obj) {
      commentClicked.value = obj
      const input = document.querySelector('#input-file2') as HTMLElement;
      input.click()
    }

    async function saveComment(comment) {
      
      const urls = await sendImage(comment) as any

      const reponse = await commentStore.createComment(comment.id_ticket, comment.description, null, comment.exibirChat, comment.id, urls.map(({ url }) => url), 'comment')
      await commentStore.getCommentsById(comment.id_ticket)

      arrayComment.value = commentStore.getComments

    }


    async function deleteComment(id, id_ticket) {
      loader.open()
      try{
        const res = await Api.delete(`comment/${id}`)
        await commentStore.getCommentsById(id_ticket)
        arrayComment.value = commentStore.getComments
      }catch(error){
        console.log("error", error)
      }finally{
        loader.close()
      }

    }

    async function getAuthenticatedUser() {
      const { data } = await Api.get('getAuthenticatedUser')
      if (!data.user) return
      user.value = data.user
    }

    function getUrls(listUrls) {
      arrayUrls.value = []
      listUrls.forEach((item) => arrayUrls.value.push(item.url))
    }

    async function filesChange(event, comment) {
      const files = Array.from(event.target.files)
      try {
        //populate list of urls to preview
        //populate list of files 
        files.forEach(file => {
          const url = URL.createObjectURL(file as any)
          const name = `file-${Math.random() * 3}`
          uploadedFiles.value.push({ name, file, url })
          if(!commentClicked.value) return;
          commentClicked.value.urls.push({ name, url })
        });
      } catch (error) {
        console.log("error", error)
      }

    }

    function removeUrl(urls, key) {
      const { url, name } = urls[key]
      urls.splice(key, 1)
      uploadedFiles.value = uploadedFiles.value.filter((e) => e.name != name)

    }

    async function sendImage(comment) {
      //enviar para azure
      let urlsAws: any[] = [];
      for (const { name, file } of uploadedFiles.value) {
        try {
          const options: TOptions = {
            blobHTTPHeaders: {
              blobContentType: file.type
            }
          }
          const fileName = `${file.name}${new Date().getTime()}`
          const { _response } = await uploadMediaFromClient(file, fileName, options)
          const url = _response.request.url
          urlsAws.push({ name, url })

        } catch (error) {
          console.log(error)
        }
      };

      const newUrls = comment.urls.filter((e) => e.id)

      return [...newUrls, ...urlsAws]

    }

    watch(
      () => props.arrayComments,
      () => {
        arrayComment.value = props.arrayComments
      }
    )

    onMounted(async () => {
      await getAuthenticatedUser()
      arrayComment.value = props.arrayComments
    })

    return {
      history,
      centerDialogVisible,
      saveComment,
      arrayComment,
      openModal,
      arrayUrls,
      getUrls,
      deleteComment,
      editar,
      isEdit,
      textoComentario,
      user,
      openInput,
      filesChange,
      removeUrl,
      uploadedFiles,
      moment
    };
  },
});
